import { setContext, setTags } from '@sentry/react'
import { differenceInDays, max } from 'date-fns'

import { User } from '../user/types'
import { Team, TeamRole } from './types'

/**
 * Check if user belongs to team, and if has one of the specified roles
 *
 * @param team
 * @param user
 * @param roles
 */
export function isTeamRole(team: Team, user: User, roles: TeamRole[]): boolean {
  return team.members.some(
    (member) => roles.includes(member.teamUser.role) && member.id === user.id,
  )
}

/**
 * Check if team is in free trial by checking if subscription has an expiration date.
 * @param team
 *
 */
export function isTeamFreeTrialSubscription(team: Team): boolean {
  const {
    subscription: { plan },
  } = team

  return plan === 'TRIAL'
}

export function isTeamTrialSubscriptionExpired(team: Team): boolean {
  const freeTrialExpirationDate = getFreeTrialExpirationDate(team)
  if (!freeTrialExpirationDate) {
    return false
  }

  return freeTrialExpirationDate.getTime() < Date.now()
}

export function getTeamFreeTrialDaysLeft(team: Team): number | null {
  const {
    subscription: { freeTrialExpiresAt, extraFreeTrialExpiresAt },
  } = team

  if (!freeTrialExpiresAt && !extraFreeTrialExpiresAt) {
    return null
  }

  const freeTrialExpiresAtDate = max([
    new Date(freeTrialExpiresAt || 0),
    new Date(extraFreeTrialExpiresAt || 0),
  ])

  return differenceInDays(freeTrialExpiresAtDate, Date.now())
}

/**
 * Helper to track the current Team in Sentry context
 * @param team
 */
export function setTeamInSentryContext(team: Team | null): void {
  setContext('currentTeam', team)
  const { id, subscription, name } = team || {}
  setTags({
    'team.subscription.production': subscription?.production,
    'team.name': name,
    'team.id': id,
  })
}

/**
 * Helper to retrieve an User's Team role from a given Team.
 * If user doesn't belong to the team, will return null.
 * @param team
 * @param user
 */
export function getUserTeamRole(team: Team, user: User): TeamRole | null {
  const currentUserTeamMember = team.members.find(
    (member) => member.id === user.id,
  )
  if (!currentUserTeamMember) {
    // user doesn't belong to team
    return null
  }

  return currentUserTeamMember.teamUser.role
}

export function getFreeTrialExpirationDate(team: Team): Date | null {
  const {
    subscription: { freeTrialExpiresAt, extraFreeTrialExpiresAt },
  } = team

  if (!freeTrialExpiresAt && !extraFreeTrialExpiresAt) {
    return null
  }

  const freeTrialExpiresAtDate = max([
    new Date(freeTrialExpiresAt || 0),
    new Date(extraFreeTrialExpiresAt || 0),
  ])

  return freeTrialExpiresAtDate
}

const ENRICHMENT_API_CUSTOMERS = [
  '480c8686-79c6-44b3-9ea4-76d121ddc155',
  '8103d5ba-1a9b-452e-8e90-372d924cddd1',
]

export const isEnrichmentApiTeam = (team: Team | null): boolean =>
  !!team && ENRICHMENT_API_CUSTOMERS.includes(team.id)
