import { AxiosResponse } from 'axios'

import { Theme } from '../../modules/user/types'
import { BaseService } from './BaseService'

export class BillingService extends BaseService {
  async fetchBillingDashboard(
    teamId: string,
    theme: Theme,
    dashboardId?: number,
  ): Promise<AxiosResponse<string>> {
    const billingDashboardUrl = this.replaceUrlParams(
      this.urls.GET_BILLING_DASHBOARD_URL,
      {
        teamId,
        theme,
        ...(dashboardId ? { dashboardId } : {}),
      },
    )

    return await this.getServiceInstance().get(billingDashboardUrl)
  }
}
