import { AxiosResponse } from 'axios'
import { all, put, select, takeEvery } from 'redux-saga/effects'

import { BillingService } from '../../lib/api/BillingService'
import { getAuth0AccessToken } from '../auth0/selectors'
import { getCurrentTeam } from '../team/selectors'
import { Team } from '../team/types'
import { getTheme } from '../user/selectors'
import { Theme } from '../user/types'
import {
  FETCH_BILLING_DASHBOARD_REQUEST,
  FetchBillingDashboardRequestAction,
  fetchBillingDashboardFailure,
  fetchBillingDashboardSuccess,
} from './actions'

function* handleFetchBillingDashboard({
  payload: { dashboardId },
}: FetchBillingDashboardRequestAction) {
  try {
    const accessToken: string = yield select(getAuth0AccessToken)
    if (!accessToken) {
      throw new Error('No access token in state, can not get billing dashboard')
    }
    const billingService: BillingService = new BillingService(accessToken)

    const currentTeam: Team | null = yield select(getCurrentTeam)

    if (!currentTeam) {
      throw new Error('No current team in state, can not get billing dashboard')
    }

    const userTheme: Theme = yield select(getTheme)

    const { data: item }: AxiosResponse<string> =
      yield billingService.fetchBillingDashboard(
        currentTeam.id,
        userTheme,
        dashboardId,
      )

    yield put(fetchBillingDashboardSuccess(item))
  } catch (error) {
    yield put(fetchBillingDashboardFailure(error as Error))
  }
}

export function* billingSaga() {
  yield all([
    takeEvery(FETCH_BILLING_DASHBOARD_REQUEST, handleFetchBillingDashboard),
  ])
}
