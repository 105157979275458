import type { Webhook } from 'pluggy-sdk'

import { WebhookEvent } from '../webhookEvents/types'

export const WEBHOOK_EVENTS: WebhookEvent['event'][] = [
  'all',
  'item/created',
  'item/updated',
  'item/error',
  'item/deleted',
  'item/waiting_user_input',
  'item/login_succeeded',
  'connector/status_updated',
  'transactions/deleted',
  'connector/status_updated',
  'payment_intent/created',
  'payment_intent/completed',
  'payment_intent/waiting_payer_authorization',
  'payment_intent/error',
  'scheduled_payment/created',
  'scheduled_payment/completed',
  'scheduled_payment/error',
  'scheduled_payment/canceled',
]

export type WebhookEventOption = {
  id: Webhook['event'] | 'all'
  name: string
}
