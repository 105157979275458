import { RouteProps } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'

import { withAuthenticationRequired } from '@auth0/auth0-react'

import { locations } from '../../modules/routing/locations'
import { isUserLoading } from '../../modules/user/selectors'
import { ApplicationPage } from '../ApplicationPage'
import { ApplicationsPage } from '../ApplicationsPage'
import { BillingPage } from '../BillingPage'
import { CallbackPage } from '../CallbackPage'
import { CompanyRolePage } from '../CompanyRolePage'
import { CustomDashboard } from '../CustomDashboard'
import { CustomizationPage } from '../CustomizationPage'
import { ItemExecutionsPage } from '../ItemExecutionsPage'
import { LogoutPage } from '../LogoutPage'
import { NoCodePage } from '../NoCodePage'
import { OnboardingPage } from '../OnboardingPage'
import { OverviewPage } from '../OverviewPage'
import { SettingsPage } from '../SettingsPage'
import { UserSettingsPage } from '../UserSettingsPage'
import { WebhookEventsPage } from '../WebhookEventsPage'

const ProtectedRoute = <P extends RouteProps>({
  component,
  ...args
}: {
  component: React.ComponentType<P>
  [key: string]: unknown
}) => <Route component={withAuthenticationRequired(component)} {...args} />

const Routes = (): JSX.Element => (
  <Switch>
    <ProtectedRoute exact path={locations.root()} component={OverviewPage} />
    <ProtectedRoute
      exact
      path={locations.overview()}
      component={OverviewPage}
    />
    <Route
      exact
      path={locations.auth0Callback()}
      component={() => (
        // This CallbackPage component will be sometime moved to an external private @pluggyai package
        <CallbackPage
          isLoadingSelectors={[isUserLoading]}
          rootLocation={locations.root()}
        />
      )}
    />
    <Route exact path={locations.logout()} component={LogoutPage} />
    <ProtectedRoute
      exact
      path={locations.events()}
      component={WebhookEventsPage}
    />
    <ProtectedRoute
      exact
      path={locations.executions()}
      component={ItemExecutionsPage}
    />
    <ProtectedRoute
      exact
      path={locations.applications()}
      component={ApplicationsPage}
    />
    <ProtectedRoute
      exact
      path={locations.application()}
      component={ApplicationPage}
    />
    <ProtectedRoute
      exact
      path={locations.settings()}
      component={SettingsPage}
    />
    <ProtectedRoute
      exact
      path={locations.customization()}
      component={CustomizationPage}
    />
    <ProtectedRoute
      exact
      path={locations.onboarding()}
      component={OnboardingPage}
    />
    <ProtectedRoute
      exact
      path={locations.user()}
      component={UserSettingsPage}
    />
    <ProtectedRoute exact path={locations.billing()} component={BillingPage} />
    <ProtectedRoute
      exact
      path={locations.customDashboard()}
      component={CustomDashboard}
    />
    <ProtectedRoute
      exact
      path={locations.companyRole()}
      component={CompanyRolePage}
    />
    <ProtectedRoute exact path={locations.noCode()} component={NoCodePage} />
    <Redirect to={locations.root()} />
  </Switch>
)

export default Routes
