const { REACT_APP_AUTH_API_URL: apiBaseUrl } = process.env

if (apiBaseUrl === undefined || apiBaseUrl.length < 4) {
  throw new Error('Must define REACT_APP_AUTH_API_URL env var as a valid URL')
}

export type UrlsMap = {
  // Activity
  GET_ACTIVITY: string

  // Applications
  GET_APPLICATIONS: string
  GET_APPLICATION: string
  POST_APPLICATION: string
  PATCH_APPLICATION: string
  DELETE_APPLICATION: string

  // Webhooks
  GET_WEBHOOKS: string
  POST_WEBHOOK: string
  PATCH_WEBHOOK: string
  DELETE_WEBHOOK: string

  // Customization
  GET_CUSTOMIZATION: string
  PATCH_CUSTOMIZATION: string
  PUT_LOGO_IMAGE: string

  // Stats
  GET_STATS_ITEMS: string
  GET_STATS_CONNECTORS: string

  // Events
  GET_WEBHOOK_EVENTS: string

  // Teams
  GET_TEAM: string
  GET_TEAMS: string
  POST_TEAM: string
  PATCH_TEAM: string
  DELETE_TEAM: string
  POST_TEAM_MEMBER: string
  PATCH_TEAM_MEMBER: string
  DELETE_TEAM_MEMBER: string
  PATCH_TEAM_INVITATION: string
  DELETE_TEAM_INVITATION: string
  RESEND_TEAM_INVITATION_EMAIL: string

  // User
  PATCH_USER: string
  DELETE_USER: string
  POST_USER_LOGIN: string
  POST_VERIFICATION_EMAIL: string

  // Connect Token
  POST_CONNECT_TOKEN: string

  // Connectors
  GET_CONNECTORS: string

  // Item
  GET_ITEM: string

  // Billing
  GET_BILLING_DASHBOARD_URL: string
}

export const urls: UrlsMap = {
  // Activity
  GET_ACTIVITY: `${apiBaseUrl}/activity`,

  // Applications
  GET_APPLICATIONS: `${apiBaseUrl}/applications`,
  GET_APPLICATION: `${apiBaseUrl}/applications/:id`,
  POST_APPLICATION: `${apiBaseUrl}/applications`,
  PATCH_APPLICATION: `${apiBaseUrl}/applications/:id`,
  DELETE_APPLICATION: `${apiBaseUrl}/applications/:id`,

  // Webhooks
  GET_WEBHOOKS: `${apiBaseUrl}/webhooks?applicationId=:applicationId`,
  POST_WEBHOOK: `${apiBaseUrl}/webhooks?applicationId=:applicationId`,
  PATCH_WEBHOOK: `${apiBaseUrl}/webhooks/:webhookId?applicationId=:applicationId`,
  DELETE_WEBHOOK: `${apiBaseUrl}/webhooks/:webhookId?applicationId=:applicationId`,

  // Webhook Events
  GET_WEBHOOK_EVENTS: `${apiBaseUrl}/webhooks/events`,

  // Customization
  GET_CUSTOMIZATION: `${apiBaseUrl}/connect/customization`,
  PATCH_CUSTOMIZATION: `${apiBaseUrl}/connect/customization`,
  PUT_LOGO_IMAGE: `${apiBaseUrl}/logo-image`,

  // Stats
  GET_STATS_ITEMS: `${apiBaseUrl}/stats/items`,
  GET_STATS_CONNECTORS: `${apiBaseUrl}/stats/connectors`,

  // Teams
  GET_TEAM: `${apiBaseUrl}/teams/:id`,
  GET_TEAMS: `${apiBaseUrl}/teams`,
  POST_TEAM: `${apiBaseUrl}/teams`,
  PATCH_TEAM: `${apiBaseUrl}/teams/:id`,
  DELETE_TEAM: `${apiBaseUrl}/teams/:id`,
  POST_TEAM_MEMBER: `${apiBaseUrl}/teams/:id/members`,
  PATCH_TEAM_MEMBER: `${apiBaseUrl}/teams/:id/members/:memberId`,
  DELETE_TEAM_MEMBER: `${apiBaseUrl}/teams/:id/members/:memberId`,
  PATCH_TEAM_INVITATION: `${apiBaseUrl}/teams/:id/invitations/:invitationId`,
  DELETE_TEAM_INVITATION: `${apiBaseUrl}/teams/:id/invitations/:invitationId`,
  RESEND_TEAM_INVITATION_EMAIL: `${apiBaseUrl}/teams/:id/invitations/:invitationId/email`,

  // User
  PATCH_USER: `${apiBaseUrl}/user`,
  DELETE_USER: `${apiBaseUrl}/user`,
  POST_USER_LOGIN: `${apiBaseUrl}/user/login`,
  POST_VERIFICATION_EMAIL: `${apiBaseUrl}/user/verification-email`,

  // Connect Token
  POST_CONNECT_TOKEN: `${apiBaseUrl}/connect-token`,

  // Connectors
  GET_CONNECTORS: `${apiBaseUrl}/connectors/?teamId=:teamId`,

  // Item
  GET_ITEM: `${apiBaseUrl}/items`,

  // Billing
  GET_BILLING_DASHBOARD_URL: `${apiBaseUrl}/billing/?teamId=:teamId&theme=:theme&dashboardId=:dashboardId`,
}

export const PLUGGY_DEMO_ORIGIN_URL = 'demo.pluggy.ai'
export const PLUGGY_DOCS_URL = 'https://docs.pluggy.ai'
export const PLUGGY_DOCS_AUTHENTICATION_URL =
  'https://docs.pluggy.ai/docs/authentication'
export const PLUGGY_CUSTOMIZATION_DOCS_URL = `${PLUGGY_DOCS_URL}/docs/customization`
export const PLUGGY_API_REFERENCE_DOCS_URL = `${PLUGGY_DOCS_URL}/reference`
export const PLUGGY_WEBHOOKS_DOCS_URL = `${PLUGGY_DOCS_URL}/docs/webhooks`
export const PLUGGY_CHANGELOG_URL = `${PLUGGY_DOCS_URL}/changelog`
export const PLUGGY_QUICKSTART_URL = 'https://github.com/pluggyai/quickstart'
