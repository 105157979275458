export const locations = {
  root: () => '/',
  auth0Callback: () => '/callback',
  logout: () => '/logout',
  overview: () => '/overview',
  events: () => '/events',
  executions: () => '/executions',
  applications: () => '/applications',
  application: (applicationId = ':applicationId') =>
    `/applications/${applicationId}`,
  settings: () => '/settings',
  user: () => '/user',
  customization: () => '/customization',
  onboarding: () => '/onboarding',
  companyRole: () => '/company-role',
  noCode: () => '/no-code',
  billing: () => '/billing',
  customDashboard: () => '/custom-dashboard',
}
