import { action } from 'typesafe-actions'

/// Fetch BillingDashboard request

export const FETCH_BILLING_DASHBOARD_REQUEST =
  '[Request] Fetch BillingDashboard'
export const FETCH_BILLING_DASHBOARD_SUCCESS =
  '[Success] Fetch BillingDashboard'
export const FETCH_BILLING_DASHBOARD_FAILURE =
  '[Failure] Fetch BillingDashboard'

export const fetchBillingDashboardRequest = (dashboardId?: number) =>
  action(FETCH_BILLING_DASHBOARD_REQUEST, { dashboardId })
export const fetchBillingDashboardSuccess = (billingDashboard: string) =>
  action(FETCH_BILLING_DASHBOARD_SUCCESS, { billingDashboard })
export const fetchBillingDashboardFailure = (error: Error) =>
  action(FETCH_BILLING_DASHBOARD_FAILURE, { error })

export type FetchBillingDashboardRequestAction = ReturnType<
  typeof fetchBillingDashboardRequest
>
export type FetchBillingDashboardSuccessAction = ReturnType<
  typeof fetchBillingDashboardSuccess
>
export type FetchBillingDashboardFailureAction = ReturnType<
  typeof fetchBillingDashboardFailure
>
